<template>
    <v-row class="fill-height">
        <v-col>
            <v-row>
                <v-col md="12">
                    <v-card>
                        <v-card-title>
                            <v-icon
                                @click="pushRouteToView('products')"
                                class="mr-4"
                                style="cursor: pointer"
                            >
                                mdi-arrow-left
                            </v-icon>
                            <v-icon class="mr-1">mdi-shopping</v-icon>
                            Produtos
                            <v-spacer></v-spacer>
                            <v-btn
                                class="mr-4"
                                color="warning"
                                :to="{ name: 'editProduct', params: { id: 1 } }"
                            >
                                <v-icon left>mdi-pencil</v-icon>
                                Editar
                            </v-btn>
                            <v-btn
                                color="success"
                                :to="{ name: 'productCreate' }"
                            >
                                <v-icon left>mdi-plus</v-icon>
                                Cadastrar
                            </v-btn>
                        </v-card-title>
                    </v-card>
                </v-col>

                <v-col md="12">
                    <v-row justify="center">
                        <v-col md="12">
                            <v-card>
                                <v-card-text>
                                    <v-row class="mb-5">
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>Código:</strong>
                                            {{ product.codigo }}
                                        </v-col>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>Classe:</strong>
                                            {{ product.classe }}
                                        </v-col>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>Unidade de Estoque:</strong>
                                            {{ product.unidade }}
                                        </v-col>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>Tipo de embalagem:</strong>
                                            {{ product.tipoEmbalagem }}
                                        </v-col>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>Descrição:</strong>
                                            {{ product.descricao }}
                                        </v-col>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>Categoria:</strong>
                                            {{ product.categoria }}
                                        </v-col>

                                        <v-col md="4" sm="12" xs="12">
                                            <strong>Fornecedor:</strong>
                                            {{ product.fornecedor }}
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>Código de barras:</strong>
                                            {{ product.codBarras }}
                                        </v-col>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>Ref. fabricante:</strong>
                                            {{ product.refFabricante }}
                                        </v-col>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>R$ Unit. entrada:</strong>
                                            {{ product.unitEntrada }}
                                        </v-col>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>Margem:</strong>
                                            {{ product.margem }}%
                                        </v-col>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>R$ Unit. saída:</strong>
                                            {{ product.unitSaida }}
                                        </v-col>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>Estoque Mínimo:</strong>
                                            {{ product.estoqueMin }}
                                        </v-col>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>Estoque Máximo:</strong>
                                            {{ product.estoqueMax }}
                                        </v-col>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>Comissiona BM:</strong>
                                            {{ product.comissionaBm ? 'Sim' : 'Não' }}
                                        </v-col>
                                    </v-row>                                    
                                </v-card-text>
                                <v-card-title>
                                    Estoque
                                </v-card-title>
                                <v-card-text>
                                    <!-- <v-row>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>Estoque atual:</strong>
                                            {{ product.estoqueAtual }}
                                        </v-col>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>Adicionar compra (Qtd):</strong>
                                            {{ product.addCompra }}
                                        </v-col>
                                        <v-col md="4" sm="6" xs="12">
                                            <strong>R$ Unit. compra:</strong>
                                            {{ product.unitCompra ? 'Sim' : 'Não' }}
                                        </v-col>
                                    </v-row> -->
                                    <v-form
                                        v-model="isValidProduct"
                                        @submit.prevent="submitEstoque()"
                                        ref="estoqueForm"
                                    >
                                        <v-col md="10" sm="12" align-self="center">
                                            <v-row>
                                                <v-col md="4" sm="12">
                                                    <v-text-field
                                                        v-model="estoqueAtual"
                                                        outlined
                                                        :rules="textRules"
                                                        label="Estoque Atual"
                                                    />
                                                </v-col>
                                                <v-col md="4" sm="12">
                                                    <v-text-field
                                                        v-model="addCompra"
                                                        outlined
                                                        type="number"
                                                        :rules="textRules"
                                                        label="Adicionar Compra"
                                                    />
                                                </v-col>
                                                <v-col md="4" sm="12">
                                                    <v-text-field
                                                        v-model="unitCompra"
                                                        outlined
                                                        :rules="textRules"
                                                        label="Unit. Compra"
                                                    />
                                                </v-col>
                                                <v-col md="12" sm="12" class="text-right">
                                                    <v-btn
                                                        type="submit"
                                                        color="rgb(61, 17, 89)"
                                                        style="color: #fff"
                                                    >
                                                        <v-icon left>
                                                            mdi-check
                                                        </v-icon>
                                                        Submit
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mask } from "vue-the-mask";
export default {
    name: 'ProductPage',
    directives: {mask},
    props: {
        id: {
            default: 0,
            type: Number,
            required: true
        }
    },
    data: () => ({
        product: {
            codigo: 'cod',
            classe: 'classe',
            unidade: 'unidade de estoque',
            tipoEmbalagem: 'Tipo de embalagem',
            descricao: 'descrição',
            categoria: 'categoria',
            fornecedor: 'fornecedor',
            codBarras: '0 123456 789012',
            refFabricante: 'ref fabricante',
            unitEntrada: 'unit entrada',
            margem: '2',
            unitSaida: 'unit saida',
            estoqueMin: 'estoque minimo',
            estoqueMax: 'estoque maximo',
            comissionaBm: false,
            estoqueAtual: 'atual',
            addCompra: 'add compra',
            unitCompra: 'R$ 10',
        },
        // v-model
        estoqueAtual: '',
        addCompra: '',
        unitCompra: '',

        isValidProduct: false,

        //rules
        textRules: [
            v => !!v || "Este campo é obrigatório"
            //v => v > 0 || "Esta campo deve ter um valor maior que zero."
        ],
    }),
    methods: {
        pushRouteToView(route) {
            this.$router.push({ name: route })
        },
        submitEstoque() {
            if (this.$refs.estoqueForm.validate()) {
                console.log('validado');
            }
        }
    }
}
</script>
